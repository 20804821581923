<template>
  <jc-dialog class="form-loading" title="备案信息" :visible.sync="dialogVisible" size="large">
    <rc-section title="主体信息">
      <component :is="principalUser" :infoData="infoData"></component>
    </rc-section>
    <rc-section title="主体资料信息">
      <confirm-picture :type="1" :infoData="infoData" v-if="role !== 5"></confirm-picture>
      <confirm-picture :type="2" :infoData="infoData"></confirm-picture>
    </rc-section>
    <rc-section v-for="item in infoData.siteInfo" :key="item.siteName" :title="`服务信息    ${item.siteName}`">
      <rc-section title="基本信息">
        <component :is="websiteUser(item.serviceType)" :siteData="item" :infoData="infoData"></component>
      </rc-section>
      <rc-section title="资料信息">
        <div v-if="eleFlag !== 1">
          <confirm-picture :type="role !== 5 ? 3 : 4" :infoData="infoData" :siteData="item"></confirm-picture>
        </div>
        <div v-else>
          <confirm-picture :type="5" :infoData="infoData" :siteData="item"></confirm-picture>
        </div>
      </rc-section>
    </rc-section>
    <rc-section :title="`其他辅助资料`">
      <confirm-picture :type="11" :infoData="infoData"></confirm-picture>
    </rc-section>
    <rc-section title="承诺书" v-if="eleFlag === 1">
      <confirm-picture :type="6" :infoData="infoData"></confirm-picture>
    </rc-section>
    <!-- <rc-section title="域名证书">
      <confirm-picture :type="5" :infoData="infoData"></confirm-picture>
    </rc-section> -->
    <span slot="footer" class="dialog-footer">
      <jc-button @click="dialogVisible = false">关 闭</jc-button>
    </span>
  </jc-dialog>
</template>
<script>
import { getAttachmentInfo, getSiteInfo, getPrincipalInfo } from '@/service/record-api'
import { SERVICE_INFO_VALUE_MAP } from '@/views/service/constant'
export default {
  props: {},
  components: {
    confirmPrincipalCommunity: () =>
      import('@/views/submit/src/record.status/components/confirm.principal.community.vue'),
    confirmPrincipalPersonal: () =>
      import('@/views/submit/src/record.status/components/comfirm.principal.personal.vue'),
    confirmPicture: () => import('@/views/submit/src/record.status/components/confirm.picture.vue'),
    confirmWebsiteCommunity: () => import('@/views/submit/src/record.status/components/confirm.website.community.vue'),
    confirmWebsitePersonal: () => import('@/views/submit/src/record.status/components/confirm.website.personal.vue')
  },
  mixins: [],
  data() {
    return {
      recordNo: this.$route.params.recordNo || '',
      infoData: {
        siteInfo: [],
        domainInfo: [],
        principalInfo: {},
        attachmentInfo: {}
      },
      dialogVisible: false
    }
  },
  mounted() {},
  created() {
    this.getInit()
  },
  watch: {},
  computed: {
    principalUser() {
      if (this.role === 5) {
        return 'confirmPrincipalPersonal'
      } else {
        return 'confirmPrincipalCommunity'
      }
    },
    websiteUser() {
      return serviceType => {
        // 1. 区分企业和个人
        // 2. 区分服务信息类型
        if (this.role === 5) {
          return serviceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP
            ? 'confirmAppPersonal'
            : 'confirmWebsitePersonal'
        } else {
          return serviceType === SERVICE_INFO_VALUE_MAP.SERVICE_INFO_APP
            ? 'confirmAppCommunity'
            : 'confirmWebsiteCommunity'
        }
      }
    }
  },
  filters: {},
  methods: {
    async getInit() {
      const promise = [this.getSiteInfo(), this.getPrincipalInfo(), this.getAttachmentInfo()]
      await Promise.all(promise)
    },
    async getAttachmentInfo() {
      let { data } = await getAttachmentInfo({ recordNo: this.recordNo })
      this.infoData.attachmentInfo = data
    },
    async getSiteInfo() {
      let { data } = await getSiteInfo({ recordNo: this.recordNo })
      this.infoData.siteInfo = data.recordSites
      this.infoData.domainInfo = data.recordDomains
    },
    async getPrincipalInfo() {
      let { data } = await getPrincipalInfo({
        recordNo: this.recordNo
      })
      this.infoData.principalInfo = data
    },
    showDialog() {
      this.dialogVisible = true
    }
  }
}
</script>
<style>
.jc-form.jc-datashow-list {
  width: 100%;
}
</style>
